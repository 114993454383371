
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { VBtn, VSimpleTable } from 'vuetify/lib';
import moment from 'moment';
import { getTimeRegexp } from '../../../util/helper';

interface OpeningHour {
  weekday: number;
  openedAt: string | null;
  closedAt: string | null;
  openedAtMenu: false;
  closedAtMenu: false;
}

@Component({
  components: { VSimpleTable, VBtn },
})
export default class VOpeningHourBundle extends Vue {
  @Prop({ type: String, default: '' }) public title!: string;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  public handledTimeSlot: OpeningHour[] = [];
  public handledWeekdays: number[] = [];

  public openedAt: string | null = '00:00';
  public closedAt: string | null = null;
  public openedAtMenu = false;
  public closedAtMenu = false;
  public updatingMode = false;
  public indexTimeSlotForEdit: number | null = null;

  get weekdays() {
    return [
      { value: 0, text: this.$t('weekdays.monday') },
      { value: 1, text: this.$t('weekdays.tuesday') },
      { value: 2, text: this.$t('weekdays.wednesday') },
      { value: 3, text: this.$t('weekdays.thursday') },
      { value: 4, text: this.$t('weekdays.friday') },
      { value: 5, text: this.$t('weekdays.saturday') },
      { value: 6, text: this.$t('weekdays.sunday') },
    ];
  }

  get shortWeekdays() {
    return [
      { value: 0, text: 'M' },
      { value: 1, text: 'T' },
      { value: 2, text: 'W' },
      { value: 3, text: 'T' },
      { value: 4, text: 'F' },
      { value: 5, text: 'S' },
      { value: 6, text: 'S' },
    ];
  }

  private sortHandledTimeSlot() {
    this.handledTimeSlot.sort((a, b) => a.weekday - b.weekday);
  }

  public getWeekdayByValue(value: number) {
    const weekday = this.weekdays.find((item) => value === item.value);
    return weekday ? weekday.text : '';
  }

  public onOpenedAt(event: string) {
    if (event && event.length === 2 && this.isInteger(event)) {
      this.openedAt = `${this.openedAt}:`;
    }
  }

  public onCloseddAt(event: string) {
    if (event && event.length === 2 && this.isInteger(event)) {
      this.closedAt = `${this.closedAt}:`;
    }
  }

  public focusCloseddAt() {
    if (!this.closedAt) {
      this.closedAt = this.openedAt;
    }
  }

  public isInteger(value: string) {
    return /^-?\d*\.?\d+$/.test(value);
  }

  public async addTimeSlot() {
    if (!(await this.$refs.observer.validate())) return;

    const overlaidTimeslot: OpeningHour[] = this.findOverlapTimeslots();

    this.handledTimeSlot = this.handledTimeSlot.filter((handledItem: OpeningHour) => {
      if (
        !overlaidTimeslot.find(
          (overlaidItem: OpeningHour) =>
            overlaidItem.weekday === handledItem.weekday &&
            overlaidItem.openedAt === handledItem.openedAt &&
            overlaidItem.closedAt === handledItem.closedAt,
        )
      ) {
        return handledItem;
      }
    });

    this.handledWeekdays.map((weekday: number) => {
      this.handledTimeSlot.push({
        weekday,
        openedAt: this.openedAt,
        closedAt: this.closedAt,
        closedAtMenu: false,
        openedAtMenu: false,
      });
    });

    this.resetForm();
  }

  public findOverlapTimeslots(): OpeningHour[] {
    let openingHour: OpeningHour[] = [];
    this.handledWeekdays.map((weekday: number) => {
      openingHour = [...openingHour, ...this.handledTimeSlot.filter((item: OpeningHour) => item.weekday === weekday)];
    });

    return openingHour.filter((item: OpeningHour) => {
      if (
        this.doTimeRangesOverlap(
          this.openedAt as string,
          this.closedAt as string,
          item.openedAt as string,
          item.closedAt as string,
        )
      ) {
        return item;
      }
    });
  }

  public resetForm() {
    this.sortHandledTimeSlot();
    this.handledWeekdays = [];
    this.openedAt = '00:00';
    this.closedAt = null;
  }

  public doTimeRangesOverlap(range1Start: string, range1End: string, range2Start: string, range2End: string) {
    const r1StartMinutes = this.parseTime(range1Start);
    const r1EndMinutes = this.parseTime(range1End);
    const r2StartMinutes = this.parseTime(range2Start);
    const r2EndMinutes = this.parseTime(range2End);

    // Check if range1Start is before range2End and range1End is after range2Start
    return r1StartMinutes < r2EndMinutes && r1EndMinutes > r2StartMinutes;
  }

  public parseTime(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  public updateTimeSlot() {
    if (this.updatingMode && this.indexTimeSlotForEdit !== null) {
      this.handledTimeSlot[this.indexTimeSlotForEdit].openedAt = this.openedAt;
      this.handledTimeSlot[this.indexTimeSlotForEdit].closedAt = this.closedAt;
    }
    this.indexTimeSlotForEdit = null;
    this.updatingMode = false;
    this.resetForm();
  }

  public removeWeekday(index: number) {
    this.handledTimeSlot.splice(index, 1);
  }

  public editWeekday(index: number) {
    const timeSlot: OpeningHour = this.handledTimeSlot[index];
    this.indexTimeSlotForEdit = index;
    this.handledWeekdays = [timeSlot.weekday];
    this.openedAt = timeSlot.openedAt;
    this.closedAt = timeSlot.closedAt;
    this.updatingMode = true;
  }

  get canCreateTimeSlot() {
    return (
      this.handledWeekdays.length > 0 &&
      getTimeRegexp().test(this.closedAt ?? '') &&
      getTimeRegexp().test(this.openedAt ?? '')
    );
  }

  public resetTimeSlot() {
    this.handledWeekdays.map((weekday: number) => {
      const timeSlotIndex: number = this.handledTimeSlot.findIndex((slot) => slot.weekday === weekday);

      if (timeSlotIndex !== -1) {
        this.handledTimeSlot.splice(timeSlotIndex, 1);
      }
    });
    this.handledWeekdays = [];
    this.openedAt = null;
    this.closedAt = null;
  }

  public handleEventBtn() {
    this.indexTimeSlotForEdit = null;
    this.updatingMode = false;
  }

  public getHours() {
    return this.handledTimeSlot.map((h: OpeningHour) => {
      return {
        ...h,
        openedAt: this.getUtcTime(h.openedAt!),
        closedAt: this.getUtcTime(h.closedAt!),
      };
    });
  }

  public setHours(hours: OpeningHour[], overwrite: boolean = false): void {
    if (overwrite) {
      this.handledTimeSlot = [];
    }
    for (const hour of hours) {
      this.handledTimeSlot.push({
        weekday: hour.weekday,
        openedAtMenu: false,
        closedAtMenu: false,
        // @ts-ignore
        openedAt: this.toLocaleTime(hour.openedAt),
        // @ts-ignore
        closedAt: this.toLocaleTime(hour.closedAt),
      });
    }
  }

  public getUtcTime(time: string): string | null {
    if (!moment(time, 'HH:mm').isValid()) {
      return null;
    }

    return moment(time, 'HH:mm').subtract(120, 'minutes').format('HH:mm');
  }

  public toLocaleTime(time: string): string | null {
    if (!moment(time, 'HH:mm').isValid()) {
      return null;
    }

    return moment(time, 'HH:mm').add(120, 'minutes').format('HH:mm');
  }

  public validate(): Promise<boolean> {
    return this.$refs.observer.validate();
  }
}
