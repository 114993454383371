
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import { SpicyLevel } from '@/enums/SpicyLevel';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Article } from '@/interfaces/models/Article';

@Component({
  components: { VFormBuilder },
})
export default class AllergensAndAdditivesForm extends mixins(StackedForm) {
  public $refs!: {
    allergens: InstanceType<typeof VFormBuilder> & { getData: () => any };
    additives: InstanceType<typeof VFormBuilder> & { getData: () => any };
    features: InstanceType<typeof VFormBuilder> & { getData: () => any };
    traces: InstanceType<typeof VFormBuilder> & { getData: () => any };
  };

  get initValues() {
    return this.initialValues as Article;
  }

  get features() {
    return [
      {
        name: 'basic',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.basic',
      },
      {
        name: 'vegan',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.vegan',
      },
      {
        name: 'vegetarian',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.vegetarian',
      },
      {
        name: 'glutenFree',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.glutenFree',
      },
      {
        name: 'noCarb',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.noCarb',
      },
      {
        name: 'alkaline',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.alkaline',
      },
      {
        name: 'lactoseFree',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.lactoseFree',
      },
      {
        name: 'new',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.new',
      },
      {
        name: 'special',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.special',
      },
      {
        name: 'topSelling',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.topSelling',
      },
      {
        name: 'warm',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.warm',
      },
      {
        name: 'cold',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.cold',
      },
      {
        name: 'fish',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.fish',
      },
      {
        name: 'chicken',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.chicken',
      },
      {
        name: 'beef',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.beef',
      },
      {
        name: 'pork',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.pork',
      },
      {
        name: 'prepackaged',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.prepackaged',
      },
      {
        name: 'spicyLevel',
        type: InputType.Select,
        label: 'article.form.features.spicyLevel.label',
        items: this.spicyLevels,
        clearable: true,
      },
    ];
  }

  get additives() {
    return [
      {
        name: 'withDye',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.1',
      },
      {
        name: 'withPreservative',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.2',
      },
      {
        name: 'withAntioxidant',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.3',
      },
      {
        name: 'withFlavorEnhancer',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.4',
      },
      {
        name: 'sulfured',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.5',
      },
      {
        name: 'blackened',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.6',
      },
      {
        name: 'withPhosphate',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.7',
      },
      {
        name: 'withMilkProtein',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.8',
      },
      {
        name: 'caffeinated',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.9',
      },
      {
        name: 'quinine',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.10',
      },
      {
        name: 'withSweetener',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.11',
      },
      {
        name: 'waxed',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.12',
      },
      {
        name: 'new',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.13',
      },
      {
        name: 'containsAlcohol',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.14',
      },
      {
        name: 'rye',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.15',
      },
      {
        name: 'nitriteCuringSalt',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.additives.16',
      },
    ];
  }

  get allergens() {
    return [
      {
        name: 'cerealsContainingGluten',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.cerealsWithGluten',
      },
      {
        name: 'crustaceansAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.crustaceans',
      },
      {
        name: 'eggsAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.eggs',
      },
      {
        name: 'fishAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.fish',
      },
      {
        name: 'peanutsAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.peanuts',
      },
      {
        name: 'soyaAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.soya',
      },
      {
        name: 'milkAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.milk',
      },
      {
        name: 'nuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.nuts',
      },
      {
        name: 'celeryAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.celery',
      },
      {
        name: 'mustardAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.mustard',
      },
      {
        name: 'sesameSeedsAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.sesame',
      },
      {
        name: 'lupinsAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.lupins',
      },
      {
        name: 'molluscsAndProductsThereof',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.molluscs',
      },

      {
        name: 'corn',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.corn',
      },
      {
        name: 'wheat',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.wheat',
      },
      {
        name: 'rye',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.rye',
      },
      {
        name: 'barley',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.barley',
      },
      {
        name: 'oats',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.oats',
      },
      {
        name: 'spelt',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.spelt',
      },
      {
        name: 'khorasan',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.khorasan',
      },
      {
        name: 'walnuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.walnuts',
      },
      {
        name: 'pecanNuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.pecanNuts',
      },
      {
        name: 'brazilNuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.brazilNuts',
      },
      {
        name: 'pistachioNuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.pistachioNuts',
      },
      {
        name: 'macadamiaNuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.macadamiaNuts',
      },
      {
        name: 'almonds',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.almonds',
      },
      {
        name: 'hazelnuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.hazelnuts',
      },
      {
        name: 'cashews',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.cashews',
      },
      {
        name: 'seeds',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.seeds',
      },
      {
        name: 'poppySeeds',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.poppySeeds',
      },
      {
        name: 'sunflowerSeeds',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.sunflowerSeeds',
      },
      {
        name: 'poultryMeat',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.poultryMeat',
      },
      {
        name: 'beef',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.beef',
      },
      {
        name: 'pigmeat',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.pigmeat',
      },
      {
        name: 'yeast',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.yeast',
      },
      {
        name: 'sulfurDioxideAndSulphites',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.sulfurDioxideAndSulphites',
      },
      {
        name: 'rye2',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.rye2',
      },
      {
        name: 'gluten',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.allergenes.gluten',
      },
    ];
  }

  get traces() {
    return [
      {
        name: 'sesame',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.sesame',
      },
      {
        name: 'soy',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.soy',
      },
      {
        name: 'peanuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.peanuts',
      },
      {
        name: 'wheat',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.wheat',
      },
      {
        name: 'lupins',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.lupins',
      },
      {
        name: 'treeNuts',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.treeNuts',
      },
      {
        name: 'sulfurDioxideAndSulfites',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.traces.sulfurDioxide',
      },
    ];
  }

  get spicyLevels() {
    return Object.values(SpicyLevel).map((level: SpicyLevel) => ({
      value: level,
      text: this.$t(`article.form.features.spicyLevel.${level}`),
    }));
  }

  public getData() {
    return {
      allergens: { ...this.$refs.allergens.form },
      additives: { ...this.$refs.additives.form },
      features: { ...this.$refs.features.form },
      traces: { ...this.$refs.traces.form },
    };
  }
}
