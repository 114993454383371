
import { Component, Prop, Vue } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { ValidationObserver } from 'vee-validate';
import { PriceType } from '@/enums/PriceType';
import { Price } from '@/interfaces/models/Price';
import { Company } from '@/interfaces/models/Company';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';

const venue = namespace('venue');

@Component({
  components: { VFormBuilder, ValidationObserver },
})
export default class PriceListForm extends Vue {
  @venue.State('active') public venue!: Venue;

  @Prop({ type: Array, default: () => [] }) public priceList!: Price[];

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  public prices: Partial<Price>[] = this.serializedPriceList();

  public getData() {
    return this.prices;
  }

  get priceType() {
    return [{ value: PriceType.EMAIL_DOMAIN, text: this.$t('article.form.emailDomain') }];
  }

  public serializedPriceList() {
    const decimal = (value: any) => value?.$numberDecimal || value || '';

    return this.priceList.map((item: Price) => {
      return {
        price: decimal(item.price),
        type: item.type,
        value: item.value,
      };
    });
  }

  public addPrice() {
    this.prices.push({
      price: null,
      type: PriceType.EMAIL_DOMAIN,
      value: '',
    });
  }

  public removePrice(item: Partial<Price>) {
    this.prices.splice(this.prices.indexOf(item), 1);
  }

  public validate() {
    return this.$refs.observer.validate();
  }
}
